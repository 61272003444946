export const downloadStatus = {
  PREPARE: 1, // 未下载,准备下载
  WAIT: 2, // 等待下载调度
  START: 3, // 开始
  DOWNLOAD: 4, // 下载中
  PAUSING: 5, // 暂停中
  PAUSED: 6, // 暂停
  CANCEL: 7, // 取消下载
  ERROR: 8, // 出错
  COMPLETE: 9, // 下载完成
  INSTALLING: 10, // 安装中
  INSTALLED: 11, // 已安装
  JUDGE: 12 // 校验文件中
}

export const errorEnum = {
  SUCCESS: 0, // 成功
  UNKNOWN: -1, // 未知错误
  URL_EMPTY: 101, // url 为空
  WHITE_ERROR: 102 // 白名单校验失败
}

export const errorMsgEnum = {
  [errorEnum.SUCCESS]: '成功',
  [errorEnum.UNKNOWN]: '未知错误',
  [errorEnum.URL_EMPTY]: 'url 为空',
  [errorEnum.WHITE_ERROR]: '白名单校验失败'
}

export const SUPPORT_WEB_DOWNLOAD_VERSION = 6560000 // 安卓 6.56 6560000
