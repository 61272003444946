import _ from 'lodash'
export class TouchUtils {
  throttleTouchInit: () => void =  () => {}
  constructor() {
    this.throttleTouchInit = _.throttle(this.touchClearFocus, 100)
  }
  touchRegister () {
    document.addEventListener('touchmove', this.throttleTouchInit)
  }
  touchClearFocus() {
    const activeElement = document.activeElement as HTMLElement | null
    if (activeElement && activeElement.tagName === 'INPUT') {
        activeElement?.blur && activeElement?.blur()
    }
  }
  touchUnRegister () {
    document.removeEventListener('touchmove', this.throttleTouchInit)
  }
}
