import styles from './styles'
import components from './componentsC'

for (const key in styles) {
  const style = styles[key]
  const component = components[style.component]

  style.id = key
  style.component = component
  style.componentName = style.componentName || ''
}

export default styles
