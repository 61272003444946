// 开发环境测试组件
import Rectangle from './rectangle/Component'
// 文本组件
import PlainText from './plain-text/Component'
import RichText from './richtext/Component'
// 视频组件
import Video from './video/Component'
import VideoFullScreen from './video-fullscreen/Component'
import VideoFixed from './video-fixed/Component'
// 表单组件
import Form from './form/Component'
import FormSimple from './form-simple/Component'
// 按钮组件
import Button from './button/Component'
import LogoButton from './logo-button/Component'
import SimpleLogoButton from './simple-logo-button/Component'
import ButtonDownloadBg from './button-download-bg/Component'
import ButtonImage from './button-image/Component'
// 图片组件
import Image from './image/Component'
import ImageMulti from './image-multi/Component'
import ImageCarouselNormal from './image-carousel-normal/Component'
import ImageCarouselCard from './image-carousel-card/Component'
import ImageCarouselTranslate from './image-carousel-translate/Component'
// 下载组件
import Download from './download/Component'
import DownloadBg from './download-bg/Component'
import DownloadButton from './download-button/Component'
import SimpleDownload from './simple-download/Component'
// 商品组件
import Goods from './goods/Component'
import GoodsMain from './goods-main/Component'
// 微信组件
import Weixin from './weixin/Component'
import WeixinButtonLarge from './weixin-button-large/Component'
import WeixinButtonSmall from './weixin-button-small/Component'
// 形状组件
import ShapeRectangle from './shape-rectangle/Component'

// 交易模版定制组件
import Introduce from './introduce/Component'
import TemplateForm from './template-form/Component'

export default {
  Rectangle,
  PlainText,
  RichText,
  Video,
  VideoFixed,
  VideoFullScreen,
  Form,
  FormSimple,
  Button,
  LogoButton,
  SimpleLogoButton,
  ButtonDownloadBg,
  ButtonImage,
  Image,
  ImageMulti,
  ImageCarouselNormal,
  ImageCarouselCard,
  ImageCarouselTranslate,
  Download,
  DownloadButton,
  SimpleDownload,
  DownloadBg,
  Goods,
  GoodsMain,
  Weixin,
  WeixinButtonLarge,
  WeixinButtonSmall,
  ShapeRectangle,
  Introduce,
  TemplateForm
}
