<template>
  <div v-if="visible && apkInfo" class="download-six-elements-wrapper">
    <div class="download-six-elements">
      <div class="line">
        <span>{{ apkInfo.displayName }}</span>
        <span class="separator">|</span>
        <span>开发者：{{ apkInfo.devName }}</span>
      </div>
      <div class="line">
        <span>更新时间：{{ apkInfo.updateTime }}</span>
        <span class="separator">|</span>
        <span>版本：{{ apkInfo.version }}</span>
        <span class="separator">|</span>
        <span class="link" @click="goTo(apkInfo.authUrl)">权限</span>
        <span class="separator">|</span>
        <span class="link" @click="goTo(apkInfo.privacyUrl)">隐私</span>
      </div>
    </div>
  </div>
</template>

<script>
import { biliBridge, inBiliApp, isSupport } from '@bilibili/js-bridge'
import { isAndroid } from '@/canvas/utils'

const MODE = {
  // 两种模式下，六要素都出现在吸顶视频组件下方，包括吸顶视频和创意联投视频
  FLOAT: 1, // 普通模式，悬浮x秒后关闭，覆盖下层元素，【客户端来做】
  ALWAYS: 2 // 严格模式，插入文档流顶部，置顶组件顺移，上滑六要素隐藏后置顶组件吸顶
}

export default {
  data() {
    return {
      apkInfo: null,
      mode: MODE.ALWAYS,
      visible: false
    }
  },
  computed: {
    isAlwaysMode() {
      return this.mode === MODE.ALWAYS
    }
  },
  mounted() {
    try {
      this.querySixElements()
    } catch (e) {}
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    goTo(url) {
      window.open(url)
    },
    querySixElements() {
      if (inBiliApp && isAndroid) {
        const handleData = ({ apkInfo, abTest }) => {
          this.apkInfo = {
            displayName: apkInfo.display_name,
            devName: apkInfo.dev_name,
            updateTime: apkInfo.update_time,
            version: apkInfo.version,
            privacyUrl: apkInfo.privacy_url,
            authUrl: apkInfo.auth_url
          }

          this.mode = +abTest?.download_whitelist_mode
          if (!this.isAlwaysMode) return

          this.visible = true
          this.$nextTick(() => {
            this.$emit('change')
          })
        }
        const getFromJSB = () => {
          const JSB_DOWNLOAD_INFO = 'cm.getDownloadInfo'
          isSupport(JSB_DOWNLOAD_INFO).then((support) => {
            if (support) {
              biliBridge.callNative({
                method: JSB_DOWNLOAD_INFO,
                data: {},
                callback: (res) => {
                  const { code, data } = res || {}
                  if (code === 0) {
                    return handleData({
                      apkInfo: data.apk_info || {},
                      abTest: JSON.parse(data.abtest || '{}')
                    })
                  }
                }
              })
            }
          })
        }

        const { download_whitelist: downloadWhiteList } = window._extraData || {}
        if (downloadWhiteList) {
          handleData({
            apkInfo: downloadWhiteList[0] || {},
            abTest: window._globalExp || {}
          })
        } else {
          getFromJSB()
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.download-six-elements-wrapper {
  position: relative;
  width: 375px;
  overflow: hidden;
}
.download-six-elements {
  position: relative;
  width: 375px;
  background-color: #f4f4f4;
  padding: 6px 10px;
  .line {
    width: 100%;
    line-height: 15px;
    font-size: 10px;
    color: #999999;
  }
  .separator {
    margin: 0 10px;
  }
  .link {
    cursor: pointer;
    color: #007cff;
  }
}
</style>
