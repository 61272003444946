export default {
  content: {
    text: '立即下载',
    link: {
      type: 'downloadEvent',
      behavior: {
        url: '',
        scheme: '',
        formId: '',
        form: {
          useTitle: 1,
          title: '',
          successMessage: '提交成功！'
        },
        ios: {
          id: '',
          url: '',
          scheme: ''
        },
        android: {
          id: '',
          url: '',
          scheme: ''
        }
      }
    }
  },
  style: {
    color: '#ffffff',
    fontSize: 16,
    backgroundColor: '#FF6699',
    borderColor: '#FF6699',
    borderWidth: 0,
    borderRadius: 4,
    animation: 'none'
  },
  layout: {
    resizeWidth: 1,
    resizeHeight: 1,
    aspectRatio: 0,
    left: 15,
    top: 13,
    width: 345,
    height: 44,
    rotate: 0
  }
}
