var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.page.blocks)?_c('div',{style:([_vm.appStyle]),attrs:{"id":"app"}},[(_vm.isTemplateH5)?_vm._l((_vm.blocks),function(block,index){return _c('div',{key:index,staticClass:"auto-block"},[(block.name !== 'fixed-block')?_vm._l((block.components),function(component){return _c('div',{key:component.id,staticClass:"auto-layout",attrs:{"id":component.id}},[_c(_vm.getComponent(component.name),{directives:[{name:"show",rawName:"v-show",value:(_vm.templateH5FormLoaded),expression:"templateH5FormLoaded"},{name:"impr",rawName:"v-impr",value:(component.id),expression:"component.id"}],tag:"component",attrs:{"component":component,"download":_vm.download,"impr-data":_vm.getReportInfo(component),"action":_vm.action,"context":"prod"},on:{"form-loaded":_vm.isTemplateH5FormLoaded}})],1)}):_vm._e()],2)}):[(!_vm.fixedTopNeedPadding && _vm.supportSixElements)?_c('download-six-elements',{ref:"sixElements",staticStyle:{"z-index":"1001"},on:{"change":_vm.changeShowSixElements}}):_vm._e(),_c('div',{staticClass:"page-container"},[(_vm.fixedTop)?_c('div',{staticClass:"fixed-top",style:(_vm.fixedTopStyle)},[(_vm.fixedTopNormal)?_c('div',{staticClass:"fixed-layout",style:(_vm.layoutStyles(_vm.fixedTopNormal.data.layout, _vm.fixedTopNormal.fixed, {
              isFixedTopNormal: true
            }))},[_c(_vm.getComponent(_vm.fixedTopNormal.name),{directives:[{name:"impr",rawName:"v-impr",value:(_vm.fixedTopNormal.id),expression:"fixedTopNormal.id"}],tag:"component",attrs:{"component":_vm.fixedTopNormal,"download":_vm.download,"impr-data":_vm.getReportInfo(_vm.fixedTopNormal),"context":"prod"}})],1):_vm._e()]):_vm._e(),(_vm.fixedBottom)?_c('div',{staticClass:"fixed-bottom"},[_c('div',{staticClass:"fixed-layout",style:({
            ..._vm.layoutStyles(_vm.fixedBottom.data.layout, _vm.fixedBottom.fixed),
            ..._vm.bottomExtra.fixedBottomBodyStyles
          })},[_c(_vm.getComponent(_vm.fixedBottom.name),{directives:[{name:"impr",rawName:"v-impr",value:(_vm.fixedBottom.id),expression:"fixedBottom.id"}],tag:"component",attrs:{"component":_vm.fixedBottom,"download":_vm.download,"impr-data":_vm.getReportInfo(_vm.fixedBottom),"context":"prod"}})],1)]):_vm._e(),_c('div',{staticClass:"fixed-bottom"},[_c('div',{staticClass:"fixed-layout",staticStyle:{"bottom":"0","left":"0","width":"100%"}},[(_vm.hasDownloadButtonBottom)?_c('div',{staticClass:"download-button-wrapper"},[_c('download-button',{attrs:{"component":_vm.autoFillDownloadComp,"download":_vm.download,"is-auto":true}})],1):_vm._e(),(_vm.hasLocationButtonBottom)?_c('location-button-bottom',{attrs:{"component":_vm.analysis.firstForm,"first-form-data":_vm.firstFormData}}):_vm._e(),(_vm.analysis.hasForm)?_c('privacy',{attrs:{"is-get-extra-data":_vm.isGetExtraData}}):_vm._e(),(_vm.isLongIOS)?_c('div',{staticClass:"ios-safe"}):_vm._e()],1)]),(_vm.fixedLeft)?_c('div',{staticClass:"fixed-left"},[_c('div',{staticClass:"fixed-layout",style:(_vm.layoutStyles(_vm.fixedLeft.data.layout, _vm.fixedLeft.fixed))},[_c(_vm.getComponent(_vm.fixedLeft.name),{directives:[{name:"impr",rawName:"v-impr",value:(_vm.fixedLeft.id),expression:"fixedLeft.id"}],tag:"component",attrs:{"component":_vm.fixedLeft,"download":_vm.download,"impr-data":_vm.getReportInfo(_vm.fixedLeft),"context":"prod"}})],1)]):_vm._e(),(_vm.fixedRight)?_c('div',{staticClass:"fixed-right"},[_c('div',{staticClass:"fixed-layout",style:(_vm.layoutStyles(_vm.fixedRight.data.layout, _vm.fixedRight.fixed))},[_c(_vm.getComponent(_vm.fixedRight.name),{directives:[{name:"impr",rawName:"v-impr",value:(_vm.fixedRight.id),expression:"fixedRight.id"}],tag:"component",attrs:{"component":_vm.fixedRight,"download":_vm.download,"impr-data":_vm.getReportInfo(_vm.fixedRight),"context":"prod"}})],1)]):_vm._e(),(_vm.fixedTopNeedPadding)?_c('div',{staticClass:"fixed-top-need-padding"},[_c('div',{class:{ 'fixed-top': !_vm.isPc },style:({
            ..._vm.fixedTopPaddingStyle,
            ..._vm.fixedTopStyle,
            ...(_vm.isPc ? { position: 'relative' } : {})
          })},[_c(_vm.getComponent(_vm.fixedTopNeedPadding.name),{directives:[{name:"impr",rawName:"v-impr",value:(_vm.fixedTopNeedPadding.id),expression:"fixedTopNeedPadding.id"}],tag:"component",attrs:{"component":_vm.fixedTopNeedPadding,"download":_vm.download,"impr-data":_vm.getReportInfo(_vm.fixedTopNeedPadding),"context":"prod"}})],1),(!_vm.isPc)?_c('div',{style:(_vm.fixedTopPaddingStyle)}):_vm._e()]):_vm._e(),(_vm.fixedTopNeedPadding && _vm.supportSixElements)?_c('download-six-elements',{ref:"sixElements",staticStyle:{"z-index":"1"},on:{"change":_vm.changeShowSixElements}}):_vm._e(),_vm._l((_vm.blocks),function(block,index){return _c('div',{key:index,staticClass:"block",style:([_vm.blockStyle(block), _vm.abTestColor])},_vm._l((block.components),function(component){return _c('div',{key:component.id,staticClass:"layout",style:(_vm.layoutStyles(component.data.layout, component.fixed)),attrs:{"id":component.id}},[_c(_vm.getComponent(component.name),{directives:[{name:"impr",rawName:"v-impr",value:(component.id),expression:"component.id"}],tag:"component",attrs:{"component":component,"download":_vm.download,"impr-data":_vm.getReportInfo(component),"action":_vm.action,"context":"prod"}})],1)}),0)}),_c('div',{style:({ height: _vm.length(_vm.fixedBottomPadding + _vm.bottomExtra.fixedBottomPadding) })}),_c('location-button',{attrs:{"blocks":_vm.blocks},on:{"show":function($event){_vm.hasLocationButtonRight = true}}})],2),(_vm.currentEnv)?_c('div',{staticClass:"env-fixed"},[_vm._v(_vm._s(_vm.currentEnv))]):_vm._e()],(_vm.isPreload)?_c('div',{staticClass:"pre-load-mask"}):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }