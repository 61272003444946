<template>
  <a v-if="buttonName" :href="`#${formId}`" :style="style" class="location-button-bottom" @click="onClick">
    <i class="iconfont iconbiaodan"></i>
    <span>{{ buttonName }}</span>
  </a>
</template>

<script>
import { get } from 'lodash'
import { MINI_click_add_form_button } from '../../constants/reportEvents'
import { getFormButtonExpInfo } from '@/canvas/utils'

export default {
  name: 'LocationButtonBottom',
  props: {
    component: {
      type: Object,
      default: () => ({})
    },
    firstFormData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    formId() {
      return get(this.component, 'data.form.id')
    },
    style() {
      const { themeColor } = this.component?.data?.style || {}
      return {
        backgroundColor: themeColor
      }
    },
    buttonName() {
      if (!this.firstFormData) return ''
      const button = this.firstFormData.items.find((item) => item.type === 'submit') || {}
      return button.label || ''
    }
  },
  mounted() {
    window._globalExp.is_add_button = 1
  },
  methods: {
    onClick() {
      this.$reportUI(MINI_click_add_form_button, {
        ...getFormButtonExpInfo()
      })
      window.hasClickFormLocationButton = true
    }
  }
}
</script>

<style lang="less" scoped>
.location-button-bottom {
  width: 100%;
  font-size: 16px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  cursor: pointer;
  background-color: #0a8afa;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  i {
    margin-right: 8px;
    font-size: 16px;
  }
}
</style>
